import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby'
import Home from "./../../images/home.svg"


import "./Breadcrumb.css";


function Breadcrumb(props) {
    const data = props;
    return (
        <div className="breadcrumb-container relative">
            {/* <div className="bread-hh container m-auto py-6 flex flex-wrap items-center px-4">
                <div>
                    <div className="w-full bread-h1">
                        <h1>{props.h1}</h1>
                    </div>
                    <div className="flex">
                        {data.list && data.list.map((dest, i) => {
                            if (i < data.list.length - 1) {
                                if (i === 0) {
                                    return (
                                        <React.Fragment>
                                            <Link to={dest.url} key={`breadcrumb-${i}`} className="cursor-pointer">
                                                <img src={Home} alt="home-icon" style={{ width: "18px" }} />
                                            </Link>
                                            <span className="px-2">
                                                -
                                    </span>
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <React.Fragment>
                                            <Link to={dest.url} key={`breadcrumb-${i}`} className="cursor-pointer flex md:hidden">
                                                {dest.text.substring(0, 8) + '...'}
                                            </Link>
                                            <Link to={dest.url} key={`breadcrumb-${i}`} className="cursor-pointer hidden md:flex">
                                                {dest.text}
                                            </Link>
                                            <span className="px-2">
                                                -
                                    </span>
                                        </React.Fragment>
                                    )
                                }
                            } else {
                                return (
                                    <span key={`breadcrumb-${i}`} style={{ color: "#3368c6" }} >
                                        { dest.text}
                                    </span>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>

            <div className="home-img-wrapper block" style={{ padding: 0, zIndex: -1 }}>
                <picture>
                    <source type="image/webp" srcset={props.image.imageFile.childImageSharp.gatsbyImageData.srcSetWebp} media="(min-width: 601px)" />
                    <source type="image/jpg" srcset={props.image.imageFile.childImageSharp.gatsbyImageData.srcSet} media="(min-width: 601px)" />
                    <img
                        srcSet={props.image.imageFile.childImageSharp.gatsbyImageData.srcSetWebp}
                        style={{ objectFit: "cover", width: "100%", height: "100%" }}
                        alt={props.image.altText}
                    />
                </picture>
            </div> */}


            <div>
                <div style={{ display: "grid" }}>
                    {/* You can use a GatsbyImage component if the image is dynamic */}
                    <GatsbyImage
                        style={{
                            gridArea: "1/1",
                            // You can set a maximum height for the image, if you wish.
                            maxHeight: 145,
                            minHeight: "145px"
                        }}
                        // You can optionally force an aspect ratio for the generated image
                        // aspectRatio={3 / 1}
                        // This is a presentational image, so the alt should be an empty string
                        alt={props?.image?.altText}
                        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash

                        loading={"eager"}

                        image={props?.image?.imageFile.childImageSharp.gatsbyImageData}

                        formats={["auto", "webp", "avif"]}
                    />
                    <div
                        style={{
                            // By using the same grid area for both, they are stacked on top of each other
                            gridArea: "1/1",
                            position: "relative",
                            // This centers the other elements inside the hero component
                            placeItems: "center",
                            display: "grid",
                        }}
                    >
                        {/* Any content here will be centered in the component */}
                        <div className="w-full container flex px-4 md:px-0">
                            <div className="w-full bread-h1">
                                <h1>{props.h1}</h1>
                            </div>
                            <div className="flex">
                                {data.list && data.list.map((dest, i) => {
                                    if (i < data.list.length - 1) {
                                        if (i === 0) {
                                            return (
                                                <React.Fragment>
                                                    <Link to={dest.url} key={`breadcrumb-${i}`} className="cursor-pointer">
                                                        {dest.text}
                                                    </Link>
                                                    <span className="px-2">
                                                        /
                                                    </span>
                                                </React.Fragment>
                                            )
                                        } else {
                                            return (
                                                <React.Fragment>
                                                    <Link to={dest.url} key={`breadcrumb-${i}`} className="cursor-pointer flex md:hidden">
                                                        {dest.text.substring(0, 8) + '...'}
                                                    </Link>
                                                    <Link to={dest.url} key={`breadcrumb-${i}`} className="cursor-pointer hidden md:flex">
                                                        {dest.text}
                                                    </Link>
                                                    <span className="px-2">
                                                        /
                                                    </span>
                                                </React.Fragment>
                                            )
                                        }
                                    } else {
                                        return (
                                            <span key={`breadcrumb-${i}`} >
                                                { dest.text}
                                            </span>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Breadcrumb;